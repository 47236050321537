export default function Header() {
  return (
    <div className="w-full h-[160px] sm2:h-[70px] bg-[#000000] flex flex-col justify-center items-center mx-auto py-4 ">
      <div className="container">
        <div className="flex flex-col items-center justify-between w-full h-full px-5 sm2:flex-row">
          <a href="/">
            <img src="./assets/Logo.png" alt="Logo" className="h-[50px]" />
          </a>
          <div className="w-full h-full flex flex-col sm2:flex-row items-center justify-center sm2:justify-end text-[16px] text-white">
            copyright @2024 Join our
            <div className="flex flex-row items-center">
              <a
                href="https://twitter.com/UndeadGenesis"
                className="mx-2 text-[#f68ed6] text-[18px] font-bold"
              >
                Twitter
              </a>
              &
              <a
                href="https://discord.com/invite/TNJsVC75Zm"
                className="mx-2 text-[#f68ed6] text-[18px] font-bold"
              >
                Discord
              </a>
            </div>
            to know more about us
          </div>
        </div>
      </div>
    </div>
  );
}
