import "./App.css";
import Wallet from "./components/wallet/Wallet";
import Header from "./pages/layout/header";
import Footer from "./pages/layout/footer";
// import Remint from "./pages/remint";
import { ToastContainer } from "react-toastify";
import { Route, Routes } from "react-router-dom";
function App() {
  return (
    <div className="w-full h-full min-h-screen flex flex-col bg-black">
      <Wallet>
        <Header />
        <div className='w-full h-full min-h-[calc(100vh-250px)] sm2:min-h-[calc(100vh-160px)] bg-[url("./assets/home.png")] bg-cover bg-center flex flex-col '>
          <Routes>
            <Route path="" element={<></>} />
            {/* <Route path="remint" element={<Remint />} /> */}
          </Routes>
        </div>
        <Footer />
      </Wallet>
      <ToastContainer style={{ fontSize: 14 }} />
    </div>
  );
}

export default App;
