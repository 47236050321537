import React from "react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { NavLink } from "react-router-dom";

export default function Header() {
  return (
    <div className="w-full h-[90px] bg-[#000000] flex flex-col justify-center items-center mx-auto">
      <div className="container">
        <div className="flex flex-row items-center justify-between w-full h-full px-5">
          <NavLink
            to="/"
            className="w-[100px] xs:w-full flex flex-row items-center gap-3"
          >
            <img src="./assets/logo.png" alt="Logo" className="h-[60px]" />
          </NavLink>
          <NavLink
            to="https://undead-genesis-1.gitbook.io/white-paper/"
            target="_blank"
            className="text-white hover:opacity-70 mr-8"
          >
            Whitepaper
          </NavLink>
          {/* <NavLink to="/remint" className="text-white hover:opacity-70">
            Remint
          </NavLink> */}
          <div className="flex flex-row max-w-[200px] items-center justify-end w-full h-full">
            <WalletMultiButton />
          </div>
        </div>
      </div>
    </div>
  );
}
